<template>
  <div class="home">
    <!-- 头图 -->
    <div class="head">
      <div class="head-title">{{ name }}</div>
      <div class="head-subtitle"></div>
      <img class="head-img" src="../assets/topimg2.png" alt="" />
      <div class="head-slogan">
        <div class="head-slogan-big">人人都能享有的私人康复师</div>
        <div class="head-slogan-small">领先的肌肉骨骼康复数字疗法</div>
      </div>
      <!--      <div class="head-backgroundcolor"></div>-->
    </div>

    <!-- 专业运动康复 -->
    <div class="features">
      <div class="features-title">
        专业运动康复 <br />
        长效摆脱疼痛
      </div>
      <div class="features-cards">
        <div class="features-cards-content">
          <img
            class="features-cards-content-img"
            src="../assets/cards_4.png"
            alt=""
          />
          <div class="features-cards-content-text">权威专家研发</div>
        </div>
        <div class="features-cards-content">
          <img
            class="features-cards-content-img"
            src="../assets/cards_2.png"
            alt=""
          />
          <div class="features-cards-content-text">AI实时检测</div>
        </div>
        <div class="features-cards-content">
          <img
            class="features-cards-content-img"
            src="../assets/cards_3.png"
            alt=""
          />
          <div class="features-cards-content-text">个性化运动处方</div>
        </div>
        <div class="features-cards-content">
          <img
            class="features-cards-content-img"
            src="../assets/cards_5.png"
            alt=""
          />
          <div class="features-cards-content-text">康复师1对1服务</div>
        </div>
      </div>
    </div>

    <!-- 个性化康复方案 -->
    <div class="treatmentplan">
      <div class="treatmentplan-title">
        针对以下症状，为用户制定个性化的康复治疗方案
      </div>
      <div class="treatmentplan-cards">
        <div
          class="treatmentplan-cards-content"
          v-for="(treatmentplan, index) in treatmentplan"
          :key="index"
        >
          <img
            class="treatmentplan-cards-content-img"
            :src="treatmentplan.img"
            alt=""
          />
          <div class="treatmentplan-cards-content-title">
            {{ treatmentplan.title }}
          </div>
          <ul class="treatmentplan-cards-content-ul">
            <li
              class="treatmentplan-cards-content-li"
              v-for="(li, index) in treatmentplan.ul.li"
              :key="index"
            >
              <text
                class="treatmentplan-cards-content-li-dot"
                v-if="treatmentplan.ul.decoration"
                >·</text
              >{{ li }}
            </li>
          </ul>
        </div>
      </div>
      <div class="treatmentplan-endtext">更多的病种类型，正在研发中...</div>
    </div>

    <!-- 临床有效性 -->
    <div class="effectiveness">
      <div class="effectiveness-title">
        创新的智能康复疗法已完成临床试验针对肌肉骨骼疼痛有效性可达
      </div>
      <img class="effectiveness-circle" src="../assets/morethan85.png" alt="" />
      <div class="effectiveness-items">
        <div
          class="effectiveness-items-content"
          v-for="(effectitem, index) in effectiveness"
          :key="index"
        >
          <div class="effectiveness-items-content-num">
            <img
              class="effectiveness-items-content-num-img"
              :src="effectitem.icon"
              alt=""
            />
            <div class="effectiveness-items-content-num-num">
              {{ effectitem.number }}
            </div>
          </div>
          <div class="effectiveness-items-content-text">
            {{ effectitem.text }}
          </div>
        </div>
      </div>
    </div>

    <!-- 独特优势 -->
    <!-- 科学研发 -->
    <div class="science">
      <div class="science-title">银杉健康的独特优势</div>
      <div class="science-subtitle">智能软件+专属康复师 双重服务 确保疗效</div>
      <img
        class="science-img"
        src="https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/fresh_img_cpys1.png"
        alt=""
      />
      <div class="science-content">
        <div class="science-content-title">权威康复专家科学研发</div>
        
        <div class="line"></div>

        <div class="science-content-content">
          银杉健康康复教研团队，联合南方医科大学康复学院院长黄国志教授带领的课题组、北京体育大学运动医学与康复学院谢思源老师带领的课题组深度合作，共同研发康复评估流程与训练方案。
        </div>
        <div class="science-content-principle">
          <div class="science-content-principle-title">
            严格遵循研发原则，确保安全性、有效性：
          </div>
          <div class="science-content-principle-content">
            <div class="science-content-principle-content-li">结合权威指南</div>
            <div class="science-content-principle-content-li">
              专家评审及指导
            </div>
            <div class="science-content-principle-content-li">
              层层筛选训练动作
            </div>
            <div class="science-content-principle-content-li">
              AI可监测且居家操作性强
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 其他三个独特优势 -->
    <div
      class="advantages"
      v-for="(advantages, index) in advantages"
      :key="index"
      :style="advantages.background_color"
    >
      <img class="advantages-img" :src="advantages.img" />
      <div class="advantages-title">{{ advantages.title }}</div>
      <div class="advantages-contentline"></div>
      <div class="advantages-content">
        <div
          class="advantages-content-text"
          v-for="(content, index) in advantages.content"
          :key="index"
        >
          <strong class="advantages-content-dot">·</strong>{{ content }}
        </div>
      </div>
    </div>

    <!-- 医学团队 -->
    <div class="team">
      <div class="team-title">医学团队</div>
      <div class="team-subtitle">权威团队 科学研发 持续迭代</div>
      <div class="team-expert">
        <div
          class="team-expert-li"
          v-for="(expert, index) in team.expert"
          :key="index"
        >
          <img
            class="team-expert-li-img"
            :src="expert.img"
            alt=""
            object-fit="contain"
          />
          <div class="team-expert-li-title">{{ expert.title }}</div>
          <div class="team-expert-li-name">{{ expert.name }}</div>
          <div class="team-expert-li-position">{{ expert.position }}</div>
        </div>
      </div>
      <div class="team-therapeutist">
        <div
          class="team-therapeutist-li"
          v-for="(therapeutist, index) in team.therapeutist"
          :key="index"
        >
          <div class="team-therapeutist-li-img">
            <img
              class="team-therapeutist-li-img"
              :src="therapeutist.img"
              alt=""
              object-fit="contain"
            />
          </div>
          <div class="team-therapeutist-li-name">{{ therapeutist.name }}</div>
          <div class="team-therapeutist-li-position">
            {{ therapeutist.position }}
          </div>
          <div class="team-therapeutist-li-title">{{ therapeutist.title }}</div>
        </div>
      </div>
    </div>

    <!-- 线上线下对比 -->
    <div class="contrast">
      <div class="contrast-title">智能康复 VS 线下诊所</div>
      <div class="contrast-subtitle">
        银杉健康以不到1/10的价格 提供更优的产品和服务
      </div>
      <div class="contrast-clinic">线下康复诊所</div>
      <div class="contrast-yinshan">银杉智能康复</div>
      <div class="contrast-content">
        <div class="contrast-content-dimension">
          <div class="contrast-content-dimension-content">医学研发</div>
          <div class="contrast-content-dimension-content">产品便捷性</div>
          <div class="contrast-content-dimension-content">康复师服务</div>
          <div class="contrast-content-dimension-content">价格说明</div>
        </div>
        <div class="contrast-content-clinic">
          <div class="contrast-content-clinic-content">
            <text class="contrast-content-clinic-content-text"
              >未系统研发和规律更新，严重依赖康复师个人经验。</text
            >
          </div>
          <div class="contrast-content-clinic-content">
            <text class="contrast-content-clinic-content-text"
              >受地域影响，无法普及所有城市；用户来回奔波费时费力。</text
            >
          </div>
          <div class="contrast-content-clinic-content">
            <text class="contrast-content-clinic-content-text"
              >根据康复师排班提供服务，不是以用户为中心，不能灵活地答疑、指导。</text
            >
          </div>
          <div class="contrast-content-clinic-content">
            <text class="contrast-content-clinic-content-text"
              >一线城市训练一次康复费用为800-1200，甚至更高。</text
            >
          </div>
        </div>
        <div class="contrast-content-yinshan">
          <div class="contrast-content-yinshan-content">
            <text class="contrast-content-yinshan-content-text"
              >大规模科学研发：跟高校、专家深入合作，共同研发；持续迭代改进：基于用户训练数据和随访反馈，不断迭代改进。</text
            >
          </div>
          <div class="contrast-content-yinshan-content">
            <text class="contrast-content-yinshan-content-text"
              >数字疗法的高可及性、便捷性，随时开始康复训练；智能训练软件实时记录多维度训练数据，紧密跟踪康复进展。</text
            >
          </div>
          <div class="contrast-content-yinshan-content">
            <text class="contrast-content-yinshan-content-text"
              >以用户为中心，随时线上问诊答疑，跟踪患者的每一次的训练情况，每周进行1对1视频沟通，总结训练情况、做健康宣教，打造陪伴式的健康管理服务。</text
            >
          </div>
          <div class="contrast-content-yinshan-content">
            <text class="contrast-content-yinshan-content-text"
              >技术改变康复，以不到十分之一的价格提供更优的产品和服务。</text
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 临床验证 -->
    <div class="verification">
      <div class="verification-title">临床验证</div>
      <div class="verification-subtitle">减少疼痛 恢复功能</div>
      <div class="verification-cards">
        <div
          class="verification-cards-card"
          v-for="(verification, index) in verification"
          :key="index"
        >
          <video
            class="verification-cards-card-img"
            :src="verification.video"
            controls
            controlslist="nodownload"
          />
          <div class="verification-cards-card-content">
            {{ verification.content }}
          </div>
          <div class="verification-cards-card-icon">”</div>
          <div class="verification-cards-card-personinfo">
            <img
              class="verification-cards-card-personinfo-avator"
              :src="verification.avator"
              alt=""
            />
            <div class="verification-cards-card-personinfo-name">
              {{ verification.name }}
            </div>
            <div class="verification-cards-card-personinfo-age">
              {{ verification.age }}
            </div>
            <div class="verification-cards-card-personinfo-disease">
              {{ verification.disease }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 科普文章 -->
    <div class="artical">
      <div class="artical-title">科普文章</div>
      <div class="artical-cards">
        <a
          class="artical-cards-card"
          v-for="(artical, index) in artical"
          :key="index"
          :href="artical.url"
          target="_blank"
        >
          <div class="artical-cards-card-topImg">
            <img :src="artical.img" alt="" />
          </div>
          <div class="artical-cards-card-content">
            <div class="artical-cards-card-content-label">
              {{ artical.label }}
            </div>
            <div class="artical-cards-card-content-title">
              {{ artical.title }}
            </div>
            <div class="artical-cards-card-content-descript">
              {{ artical.abstracts }}
            </div>
          </div>
        </a>
      </div>
      <!-- <div class="artical-seemore">查看更多</div> -->
    </div>
  </div>
</template>

<script src="../js/HomeView.js">
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// v-for="(item, index) in features" :key="index" :item="item"
// const { computed }=require("@vue/runtime-core");

// export default {
//   name: 'HomeView',
//   components: {
//     HelloWorld
//   },
// }
</script>
<style src="../style/HomeView.scss" lang="scss"></style>
