

export default {
    data() {
        return {
            treatmentplan: [{
                img: require('../assets/icon_manxingyaotong.png'),
                title: '慢性腰痛',
                ul: {
                    decoration: true,
                    li: ['腰椎间盘突出症', '腰椎滑脱症', '退行性腰椎失稳症', '梨状肌综合征', '腰肌劳损', '骶髂关节紊乱', '腰椎小关节紊乱']
                }
            }, {
                img: require('../assets/icon_yaobuweichuangshuhou.png'),
                title: '腰椎微创术后',
                ul: {
                    decoration: true,
                    li: ['腰椎微创手术（射频消融）', '腰椎椎板切除术', '腰椎融合术', '微创腰椎斜外侧椎间植骨融合术',
                        '微创经椎间孔腰椎椎体间融合术']
                }
            }, {
                img: require('../assets/icon_jizhucewan.png'),
                title: '脊柱侧弯',
                ul: {
                    decoration: true,
                    li: ['胸主弯（右/左）', '腰主弯（右/左）', '双弯（胸右腰左/胸左腰右）', '姿态不良（高低肩、长短腿）']
                }
            },],
            effectiveness: [{
                icon: require('../assets/down.png'),
                number: '68%',
                text: '平均疼痛程度减轻',
            }, {
                icon: require('../assets/down.png'),
                number: '58%',
                text: '减少抑郁和焦虑',
            }, {
                icon: require('../assets/down.png'),
                number: '60%',
                text: '避免手术',
            }, {
                icon: require('../assets/up.png'),
                number: '31.2%',
                text: '运动功能提高2倍',
            }, {
                icon: require('../assets/up.png'),
                number: '97.5%',
                text: '满意度增加',
            },],
            advantages: [{
                img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/img_cpys2.png',
                title: '资深康复师细致服务',
                // background_color: 'background-color:#F2FAF8;',
                background_color: 'background-color:#FFFFFF;',

                content: ['严选科班出身、三甲医院背景、专业过硬的康复师。', '打造细致的服务标准，密切关注用户康复进展，动态调整运动处方；一对一康复指导，打造陪伴式健康管理。']
            }, {
                img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/fresh_img_cpys3.png',
                title: '前沿AI技术重塑康复',
                // background_color: 'background-color:#F8F8F8;',
                background_color: 'background-color:#FFFFFF;',
                content: ['技术改变康复，让人人都能享有私人康复师。', '精准识别动作姿态，全程监测训练质量，实时纠正和矫正性反馈，训练效果即时展示。',
                    'AI康复师，如同把私人康复师请回家，在身边指导。']
            }, {
                img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/fresh_img_cpys4.png',
                title: '系统康复闭环确保康复效果',
                // background_color: 'background-color:#F2FAF8;',
                background_color: 'background-color:#FFFFFF;',
                content: ['评估、个性化运动处方、康复训练、随访、再评估，完整闭环康复师全周期监督你的康复流程，提升康复效果']
            }
            ],
            team: {
                expert: [{
                    img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/therapist/huangguozhi.png',
                    name: '黄国志', position: '南方医科大学康复医学院院长', title: '资深'
                }, {
                    img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/therapist/zhaoyijin.png',
                    name: '赵一瑾', position: '南方医科大学康复医学院教师', title: '资深'
                }, {
                    img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/therapist/xiesiyuan.png',
                    name: '谢思源', position: '北京体育大学康复医学院教师', title: '资深'
                }],
                therapeutist: [{
                    img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/therapist/zhangzhuodong.png',
                    name: '张卓栋', position: '康复治疗师', title: '专家'
                }, {
                    img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/therapist/zhouchenying.png',
                    name: '周陈颖', position: '康复治疗师', title: '专家'
                }, {
                    img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/therapist/zhengfuming.png',
                    name: '郑福明', position: '康复治疗师', title: '专家'
                }, {
                    img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/therapist/zhangxinna.png',
                    name: '张鑫娜', position: '康复治疗师', title: '专家'
                }, {
                    img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/therapist/fuyingjie.png',
                    name: '付英杰', position: '康复治疗师', title: '专家'
                }, {
                    img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/therapist/liusiyu.png',
                    name: '刘思雨', position: '康复治疗师', title: '专家'
                },]
            },
            // verification: [
            //     {
            //         img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/front-video-topimg.jpeg',
            //         content: '从一定的角度，从源头上帮我去砍掉了一些腰痛或者是肩颈痛的诱因',
            //         avator: require('../assets/cards_4.png'),
            //         name: '李女士',
            //         age: '36岁',
            //         disease: '· 慢性腰痛'
            //     }, {
            //         img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/front-video-topimg.jpeg',
            //         content: '从一定的角度，从源头上帮我去砍掉了一些腰痛或者是肩颈痛的诱因',
            //         avator: require('../assets/cards_4.png'),
            //         name: '李女士',
            //         age: '36岁',
            //         disease: '· 慢性腰痛'
            //     }, {
            //         img: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/front-video-topimg.jpeg',
            //         content: '从一定的角度，从源头上帮我去砍掉了一些腰痛或者是肩颈痛的诱因',
            //         avator: require('../assets/cards_4.png'),
            //         name: '李女士',
            //         age: '36岁',
            //         disease: '· 慢性腰痛'
            //     },
            // ]
            verification: [{
                video: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/patient/liyunuo2.mp4',
                content: '从一定的角度，从源头上帮我去砍掉了一些腰痛或者是肩颈痛的诱因',
                avator: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/patient/linvshi.jpg',
                name: '李女士',
                age: '36岁',
                disease: '慢性腰痛',
                id: 1
            }, {
                video: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/patient/mengqian2.mp4',
                content: '康复的路上一定要有专业人的陪伴和鼓励才能坚持下去，坚持才能让我们走向健康',
                avator: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/patient/mengnvshi.jpg',
                name: '孟女士',
                age: '34岁',
                disease: '腰椎微创术后',
                id: 2
            }, {
                video: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/patient/qinyusong2.mp4',
                content: '经过训练后，我感受到了腰部明显的好转，基本上极少再有腰部疼痛的感觉',
                avator: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/patient/qinxiansheng.jpg',
                name: '秦先生',
                age: '31岁',
                disease: '腰椎间盘突出症',
                id: 3
            }, {
                video: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/patient/gulinuo2.mp4',
                content: '晚上回家锻炼，让我腰腹部完全得到放松，同时压迫腿神经的症状已改善',
                avator: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/patient/gunvshi.jpg',
                name: '顾女士',
                age: '36岁',
                disease: '腰椎间盘突出症',
                id: 4
            }, {
                video: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/patient/wangbingxuan2.mp4',
                content: '通过运动康复锻炼和康复师的耐心指导和督促，身体状态越来越好，不适症状逐渐消失',
                avator: 'https://kangfu-action-video-1258481652.cos.ap-beijing.myqcloud.com/picture/fresh/patient/wangxiansheng.jpg',
                name: '王先生',
                age: '28岁',
                disease: '腰椎微创术后',
                id: 5
            }]
        }
    },
    computed: {
        artical() {
            return this.$store.state.artical
        }
    }
}
