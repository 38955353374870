<template>
  <!-- 头部 -->
  <nav class="nav">
    <!-- <div class="nav-backgroundcolor"></div> -->
    <img class="nav-img" src="../src/assets/logo_w.png" alt="" />
    <div class="nav-yinshan">银杉健康</div>
    <div class="nav-bar">
      <router-link
        to="/"
        class="nav-bar-content hover"
        :style="isHome ? hoverclick : ''"
        >银杉健康</router-link
      >
      <router-link
        to="/repair"
        class="nav-bar-content hover"
        :style="isRepair ? hoverclick : ''"
        >立即治疗</router-link
      >
      <!-- <text  class="nav-bar-content hover" >立即治疗</text>  -->
      <router-link
        to="/artical"
        class="nav-bar-content hover"
        :style="isArtical ? hoverclick : ''"
        >科普文章</router-link
      >
      <router-link
        to="/about"
        class="nav-bar-content hover"
        :style="isAbout ? hoverclick : ''"
        >关于我们</router-link
      >
    </div>
  </nav>
  <router-view />
  <footer class="footer">
    <div class="footer-copyright">
      Copyright © 2021-2022北京银杉未来健康科技有限公司 版权所有
    </div>
    <div class="footer-icp"><a style="color:#ffffff;text-decoration:none;" target="_blank" href="https://beian.miit.gov.cn/">京ICP备2022023932号-1</a></div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isHome: false,
      isRepair: false,
      isArtical: false,
      isAbout: false,
      hoverclick: {
        opacity: "1",
        "padding-bottom": "8px",
        "border-bottom": "3px solid #FFFFFF",
        cursor: "pointer",
      },
    };
  },
  watch: {
    $route(val) {
      // console.log(val.path, 666666666);
      if (val.path == "/") {
        this.isHome = true;
        this.isArtical = false;
        this.isRepair = false;
        this.isAbout = false;
      } else if (val.path == "/repair") {
        this.isRepair = true;
        this.isHome = false;
        this.isArtical = false;
        this.isAbout = false;
      } else if (val.path == "/artical") {
        this.isArtical = true;
        this.isHome = false;
        this.isRepair = false;
        this.isAbout = false;
      } else if (val.path == "/about") {
        this.isAbout = true;
        this.isArtical = false;
        this.isHome = false;
        this.isRepair = false;
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  list-style: none;
  text-decoration: none;
  color: #666;
}

.nav {
  width: 100%;
  /* width: 100vw; */
  position: fixed;
  /* position: relative; */
  height: 34px;
  font-family: "Alibaba-PuHuiTi-Medium";
  background-color: #121e1c;
  z-index: 100;
  opacity: 0.6;
  &-yinshan {
    position: absolute;
    font-size: 10px;
    left: 90px;
    top: 12px;
    color: #ffffff;
    z-index: 10;
  }
  &-img {
    width: 25.14px;
    height: 20px;
    position: absolute;
    left: 60px;
    top: 7px;
    z-index: 10;
  }
  &-bar {
    position: absolute;
    top: 11.5px;
    right: 60px;
    z-index: 10;
    &-content {
      margin-left: 20px;
      color: #ffffff;
      font-size: 8px;
      opacity: 0.6;
    }
  }
}
.hover:hover {
  opacity: 1;
  padding-bottom: 3px;
  border-bottom: 1.2px solid #ffffff;
  cursor: pointer;
}
.footer {
  font-family: "PingFang Regular";
  /* width: 100vw; */
  width: 100%;
  height: 46px;
  background-color: #3a3d42;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-copyright {
    font-size: 7px;
    color: #ffffff;
    opacity: 0.8;
  }
  &-icp {
    font-size: 7px;
    color: #ffffff;
    opacity: 0.5;
  }
}
</style>
