import { createStore } from 'vuex'

export default createStore({
  state: {
    current: 'home',
    artical: [
      {
        img: require('../assets/web-3.png'),
        url: 'https://mp.weixin.qq.com/s/9t2I3d-eV2UuKu5VWm9WRA',
        label: '公司介绍',
        title: '公司介绍 | 银杉未来健康科技',
        abstracts: "银杉健康是一款用于解决肌肉骨骼疼痛的数字康复产品......"
      },
      {
        img: require('../assets/web-1.jpg'),
        url: 'https://mp.weixin.qq.com/s/8QjD0APX7I22F_pPVmJJRw',
        label: '脊柱侧弯科普',
        title: '孩子站不直、坐不正?',
        abstracts: "据国家体育总局体育科学研究所2022年8月的调查研究显示......"
      },
      {
        img: require('../assets/web-2.jpg'),
        url: 'https://mp.weixin.qq.com/s/eDHUqCM6eDCPvO-tImcPfw',
        label: '运动康复科普',
        title: '为什么一定要做康复训练?',
        abstracts: "运动疗法（康复训练）是为了缓解症状或改善功能......"
      },
      {
        img: require('../assets/web-4.jpg'),
        url: 'https://mp.weixin.qq.com/s?__biz=Mzg2ODgyMjY4Mg==&mid=2247483969&idx=1&sn=fd51412582faf43b32462b39739ae841&chksm=cea739b4f9d0b0a2e5c1245e596c6204752be3964562f04ce26bf47c7915bc84dff97f097c69&mpshare=1&scene=1&srcid=0328c9QwVQHeY6R6NuvLUJrt&sharer_sharetime=1679993886403&sharer_shareid=4b3e22c96f58d726728acb5ce4b257d3&key=3d2d2b769b5840686db70a6bfe394ee779167088d1e7e3cd5a675cd6dfa523fac8025d5e3f9afbb28b9e6976b0b255ab434db69a7ed23ec2e6c65d66549be4aba7ef4d103884a11ef0428092f2c4c1bd45459d7ca7c349c290c333990f4133e359013f303c234023984a48920d883b22c2a765b8e28f47db380dc89a8e351764&ascene=0&uin=MTA3MDc5MTIw&devicetype=Windows+11+x64&version=63090217&lang=zh_CN&exportkey=n_ChQIAhIQfHXI%2FKH4bzgEw%2BlD8ZiQMhLfAQIE97dBBAEAAAAAAJDxDuPPqFMAAAAOpnltbLcz9gKNyK89dVj0J6LZ1kV7eQOIItPh5%2FjY0wLpPcuRYcplwvTsO1kOv6e1FXIuLRM5PBCS0BYXd%2B4TF2deAwBSRkjyR6abC3siV2dqM1yYniMMnsLI2KpregMFFxH5IuA3RCfTS74uoOMR2t6bebcpK1Ye%2BF10dl%2BMcCxspl1pfrwrjZHWY9K25TOutaVAQ6gTMWd%2Bo8s%2BySWqc1muWuLE0v4UkUp7Iihl6irkFePbY3fbvXhUNHWKzsLVs9W3miKAePI%3D&acctmode=0&pass_ticket=g6UPRopyVQCsY5qAjlyhyB2wLDE4%2Bdr9KAPq%2BXSsaVpjBsRKBTttR5f76ZaAcWvENbZ9p93sh%2BUAw1oQMOgQ9w%3D%3D&wx_header=1&fontgear=2',
        label: '腰痛科普',
        title: '腰痛科普 | 腰椎间盘突出症',
        abstracts: "调查显示，椎间盘突出趋于年轻化......"
      },
    ]

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
